import React, { useContext, useEffect, useState } from 'react';
import { path } from 'ramda';
import { ContentContext } from '~/_context';
import styled from 'styled-components';
import { WrappedStyled } from './styles';
import storage from 'local-storage-fallback';
import { breakpoints } from '../../_helpers/breakpoints';
import CircularProgress from '@material-ui/core/CircularProgress';

import { AiOutlineClockCircle } from "react-icons/ai";
import Feature from '../../_components/Training/Feature';
import Category from '../../_components/Training/Category';
import { Modal,
         Fade,
         Backdrop } from '@material-ui/core';
import { RiCloseCircleLine } from "react-icons/ri";

const Loader = styled.div`
    width: 100%;
    .loader{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 15px;
        svg{
            display: flex;
            color: #FFD200!important;
        }
    }
`

const ModalStyled = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  &.scrolleable{
    position: absolute;
    overflow: scroll;
    display: flex;
    align-items: flex-start;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .inner_modal_contact_message{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* width: 100vw;
    height: 100vh; */
    width: calc(100% - 30px);
    height: auto;
    padding: 5vw;
    background-color: #FFFFFF;
    outline: 0!important;
    border-radius: 16px;
    .texto_mensaje{
      width: calc(100% - 50px);
      text-align: left;
      .highlighted{
        font-size: 18px;
        line-height: 30px;
        margin-top: 10px;
      }
    }
    .errorMessages{
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-bottom: 10px;
      border: 1px dashed #F20000;
      background-color: #FF9494;
      color: #000000;
      padding: 6px 10px 6px 10px;
      font-size: 15px;
      line-height: 19px;
      p{

      }
    }
    .successMessages{
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-bottom: 10px;
      background-color: #B8FF6D;
      //#F7DECC,#AD8D75,#B8FF6D,#1B110A,#E0C0A8,#F7DECC,#AD8D75,#B8FF6D
      border: 1px dashed #33E46D;
      color: #000000;
      padding: 6px 10px 6px 10px;
      font-size: 15px;
      line-height: 19px;
    }

    .title{
      width: 80vw;
      margin-top: 0;
      //margin-bottom: 6vw;
      p{
        text-align: left;
        font-size: 7vw;
        line-height: 8.5vw;
        width: 100%;
      }
    }

    .contact_form{
      display: flex;
      flex-direction: column;
      width: 100%;

      .field_channel{
        margin-bottom: 20px;
      }
      .field_subject{
        margin-bottom: 20px;
      }
      .field_message{
        margin-bottom: 10px;
      }
      .modal_actions{
        .loader{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 250px;
          height: 40px;
          //margin-top: 6px;
          svg{
            color: #FFD200;
          }
        }
      }

      .is_invalid{
        border: 1px solid red!important;
      }
      .fix_is_invalid{
        border: 0;
      }

      .field{
        label{
          display: flex;
          width: 100%;
          font-size: 16px;
          line-height: 16px;
          font-weight: 600;
          color: #000000;
          margin-bottom: 7px;
        }
        input{
          display: flex;
          padding: 10px;
          border: 1px solid #CCCCCC;
          border-radius: 6px;
          width: 100%;
        }
        textarea{
          display: flex;
          padding: 10px;
          border: 1px solid #CCCCCC;
          border-radius: 6px;
          width: 100%;
        }
        select{
          display: flex;
          padding: 10px;
          border: 1px solid #CCCCCC;
          border-radius: 6px;
          width: 100%;
        }
      }
    }
  }

  &.scrolleable{
    padding-top: 0;
    padding-bottom: 0;
  }

  @media ${breakpoints.xs} {
    
    .inner_modal_contact_message{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: calc(100% - 30px);
      height: auto;
      padding: 25px;
      background-color: #FFFFFF;
      outline: 0!important;
      border-radius: 16px;

      .title{
        width: calc(100% - 40px);
        margin-top: 0;
        margin-bottom: 0;
        p{
          text-align: left;
          font-size: 37px;
          line-height: 40px;
          width: 100%;
        }
      }

    }
  }
  @media ${breakpoints.sm} {
    &.scrolleable{
      padding-top: 20px;
      padding-bottom: 20px;
    }
    
    .inner_modal_contact_message{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 700px;
      height: auto;
      padding: 25px;
      background-color: #FFFFFF;
      outline: 0!important;
      border-radius: 16px;

      .title{
        width: 100%;
        margin-top: 0;
        margin-bottom: 0;
        p{
          text-align: left;
          font-size: 37px;
          line-height: 40px;
          width: 100%;
        }
      }

    }
  }
  @media ${breakpoints.md} {
    
  }
  @media ${breakpoints.lg} {

  }
`
const ButtonClose = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 25px;
  cursor: pointer;
  right: 20px;
  top: 20px;
  z-index: 9;
  .icon{
    display: flex;
    font-size: 45px;
    color: #FFD200;
  }
  :hover{
    .icon{
      color: #000000;
    }
  }
`

const CampusContainer = (props) => {

    const { getCampus,
            campus, 
            setCampus } = useContext(ContentContext);
    
    const [ openModalTrainings, setOpenModalTrainings ] = useState(true);
    const handleCloseModalTrainings = () => {
        setOpenModalTrainings(!openModalTrainings);
    }

    useEffect(() => {

        const response = getCampus();
        response.then(res => {
          setCampus(path(['data', 'single'], res));
        }).catch(error => {
          //alert("timeout")
          //handleRecallCampus();
        })
        

    return () => {}
    }, [])

    const handleRecallCampus = () => {

      const response = getCampus();
      response.then(res => {
        setCampus(path(['data', 'single'], res));
      })

    }

    // if(!campus){
    //     return(
    //         <Loader>
    //           <div className="loader">
    //                 <CircularProgress size={40} />
    //             </div>
    //         </Loader>
    //     )
    // }
    
    return (
        <WrappedStyled>
          {/* <ModalStyled
                open={openModalTrainings}
                onClose={handleCloseModalTrainings}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Fade in={openModalTrainings}>
                    <div className="inner_modal_contact_message">
                        <ButtonClose onClick={() => handleCloseModalTrainings()}>
                        <div className="icon">
                            <RiCloseCircleLine />
                        </div>
                        </ButtonClose>
                        <div className='texto_mensaje'>
                        <strong>¡Hola!</strong><br/>
                        Estamos trabajando en actualizar esta sección para ofrecerte una mejor experiencia de navegación. Mientras tanto, te invitamos a acceder desde la app, que podés descargar desde el store de tu celular.
¡Gracias!
                        </div>
                    </div>
                </Fade>
            </ModalStyled> */}

            {!campus ? 
              <Loader>
                <div className="loader">
                      <CircularProgress size={40} />
                  </div>
              </Loader>
            :
              campus.categories.length ?

                <div className='container_categories'>
                {campus.categories.map((el, i) => {
                    return (
                    <Category
                        {...props}
                        id={el._id}
                        title={el.category_name}
                        elements={el.repository}
                        className="container_category"
                    />
                    )
                })}
                </div>

            : "" 
            }
            

            

        </WrappedStyled>
    );
};

export default CampusContainer;